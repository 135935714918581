<template>
  <Header hide-menu-bar="true"/>
  <div class="container">
    <div class="row">
        <div class="col" sytle="margin-top:10px">
            <div class="card negative center">
                <div class="card-inner">
                  <h2>⚠️ Sorry something went wrong!</h2>
                  <p>{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import ShopHeader from '../components/ShopHeader.vue'
import ShopFooter from '../components/ShopFooter.vue'
import Utils from '../lib/utils'

export default {
  name: 'Error',
  data: () => ({
    errorMessage: ''
  }),
  mounted () {
    document.title = 'wotFood | Error'
    const error = Utils.getQuery('e')
    if (/^[a-z-]*$/.test(error)) {
      this.errorMessage = error.replace(/-/g, ' ')
    }
  },
  components: {
    Header: ShopHeader,
    Footer: ShopFooter
  }
}
</script>
