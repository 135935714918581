<template>
  <Header hide-menu-bar="true" />
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Contact Us</h1>
        <ul>
            <li>
                <b>By Email:</b><br/>
                support@wotfood.co.uk<br/><br/>
            </li>
            <li>
                <b>By Post:</b><br/>
                wotFood Customer Care<br/>
                Systems Based Solutions Ltd<br/>
                Suite 197, 4 Blenheim Court, Peppercorn Close,<br/>
                Peterborough, PE1 2DU
            </li>
        </ul>
      </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import ShopHeader from '../components/ShopHeader.vue'
import ShopFooter from '../components/ShopFooter.vue'

export default {
  name: 'Contact',
  components: {
    Header: ShopHeader,
    Footer: ShopFooter
  }
}
</script>
