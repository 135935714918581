<template>
  <header>
    <!--
    NOTE: if you wish to make changes to the main style sheets, you must first
    uncomment the below, and then comment out the main.css.
    afterwards, just combine them and minify a new main.css. Then switch the
    comments back.
    <link rel="stylesheet" type="text/css" href="/assets/css/common.css" />
    <link rel="stylesheet" type="text/css" href="/assets/css/mobile.css" />
    -->
    <div class="header">
      <div class="row">
        <div class="col">
          <router-link to="/" type="a">
            <img src="@/assets/images/wot-logo.svg" alt="wotFood" />
          </router-link>
          <div class="links">
            <!--<div class="bars" @click="btnMenuBarClick" v-if="hideMenuBar != 'true'">
              <a>
                <span><i class="fas fa-bars" /></span>
              </a>
            </div> -->
            <div>
              <router-link v-if="hideBasket != 'true'" :to="'/basket'" type="a">
                <i class="fas fa-shopping-basket" />
                <span class="basket-count">{{ basketCount }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Cookies from '../lib/cookies.js'
import axios from 'axios'

export default {
  props: {
    hideBasket: String,
    hideMenuBar: String
  },
  data: () => ({
    basketCount: 0,
    basket: {
      shop: '',
      items: []
    }
  }),
  mounted () {
    this.emitter.on('basket-set-total', (x) => {
      this.basketCount = x
    })
    this.restoreBasketCountFromSession()
  },
  methods: {
    restoreBasketCountFromSession () {
      const app = this
      const sessionId = Cookies.getCookie('session')
      if (sessionId !== '') {
        console.log(`==> [ShopHeader] trying to restore session data for (${sessionId})`)
        axios.post('/api/shop/get-session', { session: sessionId })
          .then((data) => {
            const parsedBasket = JSON.parse(data.data)
            if (parsedBasket.basket) {
              app.basket = parsedBasket.basket
              app.basketCount = app.basket.items.length
            }
          })
          .catch(({ response }) => {
            console.log('==> [ShopHeader] unable to restore session it may be expired')
            console.log(`==> [ShopHeader] Error: ${response}`)
          })
      }
    },
    btnMenuBarClick () {
      this.emitter.emit('category-menu-clicked', true)
    }
  }
}
</script>

<style lang="scss">
header {
  @media (max-width: 799px) {
    margin-bottom: 30px;
  }
  .header {
    z-index: 100;
    height: auto;
    .col {
      display: flex !important;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .links {
      display: flex;
      align-items: center;
      height: var(--header-height);
      box-sizing: border-box;
      font-size: 32px;
      padding: 10px;
      a {
        margin-right: 15px;
        &:last-child {
          margin-right: 0px;
        }
      }
      .bars {
        padding-right: 10px;
      }
      .bars:hover {
        cursor: pointer;
      }
    }
    .right {
      width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-self: center;
      a {
        margin-right: 15px;
        .fa {
          font-size: 32px;
        }
      }
    }
    .basket-count {
      position: absolute;
      bottom: 50px;
      margin-left: 20px;
    }

    .nav-lock {
      @extend .basket-count;
      background: transparent;
      color: #333;
      font-size: 16px;
    }

    .drop-down {
      display: none;
      z-index: 1000;
      width: 100%;
      height: auto;
      background: var(--negative-bg-color);
      padding: 20px;
      margin-top: -25px;
      transition: transform 0.3s;
      color: var(--accent-color);
      &[aria-expanded="true"] {
        display: block;
      }

      .inline-mobile {
        display: block;
        overflow-x: hidden;
        li {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.page {
  padding-bottom: 10px;
}

.mobile {
  .header {
    .col {
      display: flex !important;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.mobile-nav {
  display: none;
  @media (max-width: 799px) {
    display: inline-block;
  }
}
</style>
