<template>
  <Header hide-menu-bar="true" />
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Cookie Policy</h1>
        <small>Version 1.0</small>

        <h4>Our cookie policy in brief</h4>
        <p>A cookie allows us to distinguish you from other users of our site and recognises your preferences, provides personalised content, security and enables our site to function more efficiently. This helps us to provide you with best experience possible when you browse; it allows us to make our site better for you. Cookies are stored on your computer or electronic device unless you object (see below for more details). We use session and persistent cookies. Session cookies are cookies that expire at the end of your browsing session, whereas persistent cookies are stored on your device in-between sessions.</p>

        <h4>Our full cookie policy</h4>
        <b>Types of cookies</b>
        <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer or electronic device unless you object (see below). Our websites wotfood.com and wotfood.co.uk (“our site”) and each of the web pages and subdomains (including www. wotfood.com, www.wotfood.co.uk, hub. wotfood.co.uk and app. wotfood.com) use two types of cookies: first and third party cookies. First party cookies are cookies set by us, compared to third party cookies which are set by someone other than us and which collects information about you which may be sent to the third party. This helps to distinguish you from other users of our site and gives you the best possible experience when you browse our site, and also allows us to improve our site.</p>

        <p>The categories of cookies hosted on our site are known as:</p>
        <ul>
            <li>• <b>essential/necessary cookies for running the website.</b> These type of cookies help you to access our site and each of its web pages and subdomains, by enabling basic functions like cookie consent; and</li>
            <li>• <b>non-essential cookies including analytical/performance, functionality and targeting cookies.</b>These type of cookies collect anonymous statistics to enable us to improve our site and each of its pages and subdomains; provide you with full functionality of our site (including our third party customer service chat widget and any embedded videos); and third party cookies to collect and build a profile of your interest and show you relevant advertisements on other websites. These types of cookies do not store personal information in itself, but are based on a unique identification in your browser and devices which could identify you if combined with other relevant data (see below for more information about third party cookies).</li>
        </ul>

        <p>Our online ordering website (wotfood.co.uk) only uses <b>essential/necessary cookies.</b></p>

        <h4>Third Party Cookies</h4>

        <p>We apply the following third party cookies on our website; where applicable. These categories of cookies are analytical/performance cookies and targeting cookies and you can review the third party cookies policies via the below links.</p>

        <p> - None (zero)</p>

        <h4>Control these cookies</h4>

        <p>You can change your cookies preference at any time by visiting the cookie policy page. Alternatively, you can restrict the use cookies within your browser, by activating its setting that allows you to refuse all or some cookies. If you have enabled third party persistent cookies on our site before, you will need to visit the third party website to set your privacy choices to decide how your information is used by that third party.</p>
        <p>Except for essential and necessary and session cookies, any applicable wotfood.com cookies set by us will expire after 90 days and after this time you will be asked to re-consent to the use of non-essential cookies. Third party and other cookies will expire in accordance with their individual cookie policies.</p>
        <p>We may revise this cookie policy at any time by amending this page. Please check it once in a while to notice any changes we may make from time to time.</p>
        <hr/>

        <h4>Cookies help us keep you and wotFood safe, give you a better experience and show you more relevant ads</h4>

        <p>Think of cookies as stickers - when you visit a site, the site puts a sticker on you so that it knows if you come back and can collect information about your visit. That lets the site show things relevant to you, based on information you’ve entered and stuff you’ve looked at. The length of time stored depends on the cookie, but this is generally between a few minutes and up to two years</p>
        <p>We use these kinds of cookies on wotfood.co.uk:</p>
        <ul>
            <li>• Cookies to help keep you and wotFood safe</li>
            <li>• Cookies to give you a better experience</li>
        </ul>

        <p>The cookies we use never store or access any of your banking details.</p>

        <h4>Cookie Settings</h4>
        <b>Cookies that help keep you and wotFood safe</b>
        <p>Some cookies help us keep you and us safe. Because we need them to keep our site secure, you can't turn them off. For example, we use Cloudflare cookies to protect our site against attacks.</p>

        <b>Cookies that give you a better experience</b>
        <p>We reserve the right to use analytics cookie, but we are not using any right now.</p>
        <p>We also use cookies to recognise you when you come back to our site. When we spot it’s you, we know whether or not to show you things you’ve already.</p>

        <hr/>

        <h4>Other ways to turn off cookies</h4>
        <p>You can also turn cookies off in your browser or phone settings.</p>
        <p>For this, try looking in your Internet browser's help menu, or searching for ‘how to block cookies’. Your browser also lets you delete any cookies stored on your device, whenever you wish.</p>

        <h4>Questions</h4>

        <p>We take privacy very seriously and are always open to your feedback. If you have any more questions about our cookie notice or how we use cookies please email us at support@wotfood.co.uk</p>
      </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import ShopHeader from '../components/ShopHeader.vue'
import ShopFooter from '../components/ShopFooter.vue'

export default {
  name: 'Cookies',
  components: {
    Header: ShopHeader,
    Footer: ShopFooter
  }
}
</script>
