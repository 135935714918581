<template>
  <div id="mainWrapper">
  <Header hide-menu-bar="true" />
      <div class="container">
        <div class="row">
          <div class="col no-padding alternative flex-6">
            <div class="row alternative">
              <div class="col flex-4">
                <div class="row">
                  <div class="col">
                    <div class="card focus center">
                      <div class="card-inner">
                        <b v-if="shopStatus=='closed'">
                          <br/>
                          💬 Sorry we're closed at the moment!
                          <br/>
                          <br/>
                        </b>
                        <b v-if="shopStatus=='suspended'">
                          <br/>
                          💬 Sorry this shop is not able to serve any customers at this time!
                          <br/>
                          <br/>
                        </b>
                        <b v-if="shopStatus=='not-registered'">
                          <br/>
                          💬 Sorry this shop is not yet registered with wotFood!
                          <br/>
                          <br/>
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col no-padding">
                    <div class="card primary">
                      <div class="card-inner">
                        <div class="row">
                          <div class="col no-padding">
                            <p class="center">
                              <strong>Please review your order before checkout</strong>
                            </p>
                          </div>
                        </div>
                        <p class="center">
                          <table class="table">
                            <tr v-for="(item, idx) in basket.items" :key="idx">
                              <td>{{ item.qty }}x </td>
                              <td>
                                {{ item.name }} <small>@£{{ (item.price/100).toFixed(2) }}</small>
                                <span v-if="item.mergedOptions.length > 0" style="font-size:small">
                                  <br/>
                                  <span v-for="(subOps, idx) in item.mergedOptions" :key="idx">
                                     + (£{{ (subOps.price/100).toFixed(2) }}) <b>{{ subOps.title }}:</b> {{ subOps.name }}<br/>
                                  </span>
                                </span>
                              </td>
                              <td><span class="item-price unselectable">£{{ calculateSubTotal(item) }}</span></td>
                            </tr>
                          </table>
                        </p>
                        <div v-if="basket.items.length > 0" class="row">
                           <div class="col no-padding">
                              <p class="center">
                                <router-link :to="'/basket'"><i class="fas fa-angle-left" /> Return back to basket</router-link>
                              </p>
                           </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if=" calculateGrandTotal() > 0 && shopStatus=='open'">
                  <div class="col no-padding">
                    <div class="card primary">
                      <div class="card-inner">
                        <p class="center" v-if="discount > 0">
                          <i class="fas fa-check-circle" /> &nbsp; Discount: £{{ (discount/100).toFixed(2) }} ({{ basket.couponCode.toUpperCase() }})
                        </p>
                        <p class="center">
                          <strong>Total: £{{ calculateGrandTotal() }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="shopStatus=='open'">
                  <div class="col no-padding">
                    <div class="card primary">
                      <div class="card-inner">
                        <div class="row">
                          <div class="col">
                            <p class="center"><strong><i class="fas fa-truck"/> Delivery Details</strong></p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <span v-if="isPickUpOnly">
                              Sorry, no deliveries. Collection orders only
                              <br/>
                              <br/>
                            </span>
                            <span style="float:left;" class="clicky" @click="setDeliveryMethod('collection')">
                              Collection <i v-if="deliveryMethod == 'collection'" class="fas fa-check-circle highlight"/>
                            </span>
                            <span v-if="!isPickUpOnly" style="float:left;" class="clicky" @click="setDeliveryMethod('delivery')">
                              Delivery <i v-if="deliveryMethod == 'delivery'" class="fas fa-check-circle highlight"/>
                            </span>
                          </div>
                        </div>
                        <div class="row">
                            <div class="col">
                              <input type="text" placeholder="Email" id="email" v-model="email" :class="validation.email" />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <input type="text" placeholder="Phone" id="phone" v-model="phone" :class="validation.phone" />
                            </div>
                          </div>
                        <div :class="ShowDeliveryPanel()" >
                          <div class="row">
                            <div class="col">
                              <input type="text" placeholder="Address Line 1" id="address1" v-model="address1" :class="validation.address1" />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <input type="text" placeholder="Address Line 2" id="address2" v-model="address2" :class="validation.address2" />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <input type="text" placeholder="Postcode" id="postcode" v-model="postcode" :class="validation.postcode" />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <textarea name="delivery-note" id="delivery-note" cols="30" rows="3" placeholder="Order Note" v-model="notes" maxlength="280"></textarea>
                            <small>{{ notes.length }}/280</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="shopStatus=='open'">
                  <div class="col no-padding">
                    <div class="card primary">
                      <div class="card-inner">
                        <div class="row">
                          <div class="col">
                            <p class="center"><strong><i class="fas fa-credit-card"/> Payment Method</strong></p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <span style="float:left;" class="clicky" @click="setPaymentMethod('cash')">
                              Cash <i v-if="paymentMethod == 'cash'" class="fas fa-check-circle highlight"/>
                            </span>
                            <span style="float:left;" class="clicky" @click="setPaymentMethod('credit')">
                              Card <i v-if="paymentMethod == 'credit'" class="fas fa-check-circle highlight"/>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="shopStatus=='open'">
                  <div class="col no-padding">
                    <div class="card primary">
                      <div class="card-inner">
                        <div class="row">
                          <div class="col">
                            <p class="center"><strong><i class="fas fa-clock"/> Requested Time</strong></p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <span style="float:left;" class="clicky" @click="setRequestedMethod('asap')">
                              As soon as possible <i v-if="requestedMethod == 'asap'" class="fas fa-check-circle highlight"/>
                            </span>
                            <span style="float:left;" class="clicky" @click="setRequestedMethod('custom')">
                              At or after a specific time <i v-if="requestedMethod == 'custom'" class="fas fa-check-circle highlight"/>
                            </span>
                            <span v-if="requestedMethod == 'custom'" class="custom-time">
                                <div style="display:flex">
                                <select v-model="requestedTimeHours">
                                    <option value="-1">Hour</option>
                                    <option v-for="(hour, idx) in getHours()" :key="idx">
                                        {{ hour.toString().padStart(2, 0) }}
                                    </option>
                                </select>
                                <select v-model="requestedTimeMinutes">
                                    <option value="-1">Minutes</option>
                                    <option v-for="(minute, idx) in getMinutes()" :key="idx">
                                        {{ minute.toString().padStart(2, 0) }}
                                    </option>
                                </select>
                                </div>
                            </span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col primary">
                            <p class="center">
                              <button class="button place-order" @click="btnSubmitOrder"><i class="fas fa-thumbs-up" /> Place Order</button>
                            </p>
                            <b v-if="checkoutError != ''" class="error">{{ checkoutError }}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal modal-order" :class="[checkoutModal ? 'open' : 'closed']">
          <div class="modal-content">
            <div class="row">
              <div class="col">
                <div class="content">
                    <h3>Processing Order</h3>
                    <p>
                      We are contacting the shop. Please do not refresh or leave your screen
                    </p>
                    <img src="@/assets/images/loader.gif" width="150px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  <Footer/>
  </div>
</template>

<script>
import ShopHeader from '../components/ShopHeader.vue'
import ShopFooter from '../components/ShopFooter.vue'
import Cookies from '../lib/cookies.js'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'
var STRIPE = null

export default {
  name: 'Basket',
  components: {
    Header: ShopHeader,
    Footer: ShopFooter
  },
  data: () => ({
    shopName: '',
    sessionId: '',
    basket: {
      slug: '',
      shopName: '',
      couponCode: '',
      companyId: 0,
      items: [],
      tx: ''
    },
    discount: 0,
    deliveryMethod: '',
    paymentMethod: '',
    requestedMethod: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    postcode: '',
    notes: '',
    requestedTimeHours: 0,
    requestedTimeMinutes: 0,
    checkoutError: '',
    checkoutModal: false,
    shopStatus: '',
    validation: {
      email: '',
      phone: '',
      address1: '',
      address2: '',
      postcode: ''
    },
    isPickUpOnly: false
  }),
  async mounted () {
    document.title = 'wotFood | Checkout'
    window.scroll(0, 0)
    console.log('==> loading customer details from cache')
    this.email = Cookies.getCookie('email')
    this.phone = Cookies.getCookie('phone')
    this.address1 = Cookies.getCookie('address1')
    this.address2 = Cookies.getCookie('address2')
    this.postcode = Cookies.getCookie('postcode')

    this.restoreBasetFromSession()
    const h = location.host
    if (h.includes('localhost') || h.includes('stage') || h.includes('dev') || h.includes('test') || h.includes('neo')) {
      console.log('==> detected test environment loading stripe TEST public key')
      STRIPE = await loadStripe('pk_test_rbzyJahTSWOmPn6Rn8xMAGFT')
    } else {
      console.log('==> detected production environment loading stripe PROD public key')
      STRIPE = await loadStripe('pk_live_51MSi2KBt7qisAVRwZnLn4jFCST98xQ7GXuXZS8Uw6TMXOEqMdAbOR0hRVoT1Xo0LjtpW3UhXMy2Qmr7q3c4W2BCl00FIduTWaL')
    }
  },
  methods: {
    ShowDeliveryPanel () {
      return this.deliveryMethod === 'delivery' ? '' : 'hide'
    },
    restoreBasetFromSession () {
      const app = this
      const sessionId = Cookies.getCookie('session')
      if (sessionId !== '') {
        console.log(`==> [Basket] trying to restore session data for (${sessionId})`)
        this.sessionId = sessionId
        axios.post('/api/shop/get-session', { session: sessionId })
          .then((data) => {
            const parsedBasket = JSON.parse(data.data)
            if (parsedBasket.basket) {
              app.basket = parsedBasket.basket
              app.shopName = app.basket.shopName
              if (app.basket.couponCode !== '') {
                this.applyCoupon()
              }
              app.getShopStatus()
              app.getShopIsPickUpOnly()
            }
          })
          .catch(({ response }) => {
            console.log('==> [Basket] unable to restore session it may be expired')
            console.log(`==> [Basket] Error: ${response}`)
          })
      }
    },
    applyCoupon () {
      const payload = {
        session: this.sessionId,
        code: this.basket.couponCode,
        companyId: this.basket.companyId
      }
      axios.post('/api/shop/apply-discount', payload)
        .then((data) => {
          this.discount = data.data.discount
        })
        .catch(({ response }) => {
          console.log(JSON.stringify(response.data))
        })
    },
    updateBasketCount () {
      this.emitter.emit('basket-set-total', this.basket.items.length)
    },
    saveSession () {
      console.log('==> [Basket] saving current session')
      axios.post('/api/shop/update-session', { session: this.sessionId, data: { basket: this.basket } })
        .then((data) => {
          console.log('==> [Shop] session saved')
        })
        .catch(({ response }) => {
          alert(`error: -> ${JSON.stringify(response)}`)
        })
    },
    cacheCustomerDetails () {
      console.log('==> caching customer details')
      Cookies.setCookie('email', this.email)
      Cookies.setCookie('phone', this.phone)
      Cookies.setCookie('address1', this.address1)
      Cookies.setCookie('address2', this.address2)
      Cookies.setCookie('postcode', this.postcode)
    },
    calculateSubTotal (item) {
      if (item.mergedOptions.length > 0) {
        let optionTotal = 0
        item.mergedOptions.forEach(sub => {
          optionTotal += sub.price
        })
        return ((item.qty * (item.price + optionTotal)) / 100).toFixed(2)
      } else {
        return ((item.qty * item.price) / 100).toFixed(2)
      }
    },
    calculateGrandTotal () {
      let total = 0
      this.basket.items.forEach(item => {
        if (item.mergedOptions.length > 0) {
          let optionTotal = 0
          item.mergedOptions.forEach(sub => {
            optionTotal += sub.price
          })
          total += ((item.qty * (item.price + optionTotal)))
        } else {
          total += ((item.qty * item.price))
        }
      })
      return ((total - this.discount) / 100).toFixed(2)
    },
    getShopStatus () {
      axios.post('/api/shop/get-shop-status', { companyId: this.basket.companyId })
        .then((data) => {
          this.shopStatus = data.data
        })
        .catch(({ response }) => {
          alert(JSON.stringify(response))
        })
    },
    getShopIsPickUpOnly () {
      axios.post('/api/shop/get-shop-is-pickup-only', { companyId: this.basket.companyId })
        .then((data) => {
          this.isPickUpOnly = data.data
          if (this.isPickUpOnly) {
            this.deliveryMethod = 'collection'
          }
        })
        .catch(({ response }) => {
          alert(JSON.stringify(response))
        })
    },
    setDeliveryMethod (method) {
      this.deliveryMethod = method
    },
    setPaymentMethod (method) {
      this.paymentMethod = method
    },
    setRequestedMethod (method) {
      this.requestedMethod = method
    },
    getMinutes () {
      return Array.from(Array(12).keys()).map((v, i) => i * 5)
    },
    getHours () {
      return Array.from(Array(23).keys()).map((v, i) => i * 1)
    },
    btnSubmitOrder () {
      this.checkoutError = ''
      this.resetValidations()
      // some basic checks:
      if (this.deliveryMethod === '') {
        this.checkoutError = 'Please select the delivery method! (collection or delivery)'
        return
      }
      if (this.paymentMethod === '') {
        this.checkoutError = 'Please select the payment method! (cash or credit)'
        return
      }
      if (this.requestedMethod === '') {
        this.checkoutError = 'Please select the requested time! (asap or at a specific time)'
        return
      }
      // TODO: check that the hour/minute has been selectd
      if (this.requestedTimeHours === '-1') {
        this.checkoutError = 'Please select the requested time hour from the drop down!'
        return
      }
      if (this.requestedTimeMinutes === '-1') {
        this.checkoutError = 'Please select the requested time minute from the drop down!'
        return
      }
      this.checkoutModal = true
      const app = this
      const payload = {
        sessionId: this.sessionId,
        companyId: this.basket.companyId,
        deliveryMethod: this.deliveryMethod,
        email: this.email.replaceAll(' ', ''),
        phone: this.phone,
        address1: this.address1,
        address2: this.address2,
        postcode: this.postcode.replaceAll(' ', ''),
        notes: this.notes,
        coupon: this.basket.couponCode,
        paymentMethod: this.paymentMethod,
        requestedMethod: this.requestedMethod,
        requestedTimeHours: parseInt(this.requestedTimeHours),
        requestedTimeMinutes: parseInt(this.requestedTimeMinutes)
      }
      axios.post('/api/shop/submit-order', payload)
        .then((data) => {
          console.log(JSON.stringify(data.data))
          /* ---------------------------------------------------------------------------------------
          we should either get back:
            * transactionId (cash flow)
            * stripeSessionId (trigger credit flow/redirect)
          ## Cash flow (transactionId)
          we save the tx (transactionId) into the session data, we can then trigger the
          "check status" loop with loading animation.
          If the user closes the browser or refreshes, or navigates away. On page re-load we will
          always check for the tx value if it exists we will trigger the "check status" mode.
          depending on the response we get back from the server e.g :
            * accepted
            * rejected
            * timedout
          we need to show the correct final page (e.g thanks! , rejected, timeout) and at this point
          we need to nuke the session.
          For the thanks/rejected/timeout page we can pass the tx in the queryString because we nuke
          the session cookie, but we still need to reload the final status.
          ## Credit Flow (stripeSessionId)
          we redirect to stripe and then stripe redirects back to our authorise/cancel page which then
          updates the status and we get back a tx, at that point we follow the same as the cash flow
          and trigger the "check status" mode.
          --------------------------------------------------------------------------------------- */
          if (data.data.transactionId) {
            console.log(`we have cash flow -> ${data.data.transactionId}`)
            app.basket.tx = data.data.transactionId
            app.saveSession()
            app.cacheCustomerDetails()
            setTimeout(() => {
              app.$router.push({
                name: 'OrderStatus',
                query: {
                  tx: app.basket.tx
                }
              })
              app.checkoutModal = false
            }, 2000)
          }
          if (data.data.stripeSessionId) {
            console.log(`we have credit flow -> ${data.data.stripeSessionId}`)
            app.cacheCustomerDetails()
            STRIPE.redirectToCheckout({
              sessionId: data.data.stripeSessionId
            }).then((result) => {
              alert(result.error.message)
            })
          }
        })
        .catch(({ response }) => {
          app.checkoutModal = false
          if (response.data.error) {
            app.checkoutError = response.data.error
          } else {
            app.checkoutError = response.data
          }
          if (app.checkoutError.includes('email')) {
            this.validation.email = 'validation-failed'
          }
          if (app.checkoutError.includes('phone')) {
            this.validation.phone = 'validation-failed'
          }
          if (app.checkoutError.includes('address line 1')) {
            this.validation.address1 = 'validation-failed'
          }
          if (app.checkoutError.includes('address line 2')) {
            this.validation.address2 = 'validation-failed'
          }
          if (app.checkoutError.includes('postcode')) {
            this.validation.postcode = 'validation-failed'
          }
        })
    },
    resetValidations () {
      this.validation.email = ''
      this.validation.phone = ''
      this.validation.address1 = ''
      this.validation.address2 = ''
      this.validation.postcode = ''
    }
  }
}
</script>

<style lang="scss">
.checkout-button-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a,
  .checkout-button {
    width: 100%;
    max-width: 300px;
  }

  input {
    width: 300px;
  }
}

.return-button {
  width: 200px;
}

.error {
  color: #ff5c33;
}

.highlight {
  color: var(--accent-color);
}

.custom-time {
    margin-left: 18px;
    margin-top: 10px;

    select {
        margin-right: 10px;
    }
}

.hide {
  display: none;
}

.place-order {
  width: 100%;
  background-color: var(--accent-color) !important;
}

.clicky {
  color: #f58427;
  cursor: pointer;
}
</style>
