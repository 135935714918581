<template>
  <div id="mainWrapper">
  <Header hide-basket="true" hide-menu-bar="true"/>
      <div class="container">
        <div class="row">
          <div class="col no-padding alternative flex-6">
            <div class="row alternative">
              <div class="col flex-4">
                <div class="row" v-if="currentStatus.title != ''">
                  <div class="col no-padding">
                    <div class="card primary">
                      <div class="card-inner">
                        <h1 class="title center">{{ currentStatus.title }}</h1>
                        <p class="center">{{ currentStatus.message }}</p>
                        <p class="center" v-if="status == 'accepted' && order.requestedMethod == 'asap'">Order should be ready for collection or delivered around <b>{{ prettyPrintTime(order.acceptedTime) }}</b> or later.</p>
                        <p class="center" v-if="status == 'accepted' && order.requestedMethod !='asap'">Order has been requested for <b>{{ doubleZero(order.requestedHour) }}:{{ doubleZero(order.requestedMinute) }}</b> or later.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col no-padding">
                    <div class="card primary">
                      <div class="card-inner">
                        <div class="row">
                          <div class="col no-padding">
                            <p class="center">
                              <strong>{{ shopName }}</strong>
                            </p>
                          </div>
                        </div>
                        <p class="center">
                          <table class="table">
                            <tr v-for="(item, idx) in basket.items" :key="idx">
                              <td>{{ item.qty }}x </td>
                              <td>
                                {{ item.name }} <small>@£{{ (item.price/100).toFixed(2) }}</small>
                                <span v-if="item.mergedOptions.length > 0" style="font-size:small">
                                  <br/>
                                  <span v-for="(subOps, idx) in item.mergedOptions" :key="idx">
                                     + (£{{ (subOps.price/100).toFixed(2) }}) <b>{{ subOps.title }}:</b> {{ subOps.name }}<br/>
                                  </span>
                                </span>
                              </td>
                              <td><span class="item-price unselectable">£{{ calculateSubTotal(item) }}</span></td>
                              <td></td>
                            </tr>
                          </table>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="order.total">
                  <div class="col no-padding">
                    <div class="card primary">
                      <div class="card-inner">
                        <p class="center" v-if="discount > 0">
                          <i class="fas fa-check-circle" /> &nbsp; Discount: £{{ (discount/100).toFixed(2) }}
                        </p>
                        <p class="center">
                          {{ order.customerEmail }} ({{ order.customerPhone }})
                          <br/>
                          <strong>{{ order.customerPostcode }}</strong>
                          <br/>
                          <strong>Discount: -£{{ (order.discount/100).toFixed(2) }}</strong>
                          <br/>
                          <strong>Delivery Fee: £{{ (order.shipping/100).toFixed(2) }}</strong>
                          <br/>
                          <strong>Total: £{{ (computeGrandTotal/100).toFixed(2) }}</strong>
                          <br/>
                        </p>
                        <div class="checkout-button-wrap" style="padding: 10px">
                          <p class="error" v-if="couponError !== ''">{{ couponError }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal modal-order" :class="[statusModal ? 'open' : 'closed']">
          <div class="modal-content">
            <div class="row">
              <div class="col">
                <div class="content">
                    <h3><i class="fas fa-store" /> Contacting the shop...</h3>
                    <p>
                      Please do not refresh or leave your screen
                    </p>
                    <img src="@/assets/images/loader.gif" width="150px" />
                </div>
              </div>
            </div>
          </div>
      </div>
  <Footer/>
  </div>
</template>

<script>
import ShopHeader from '../components/ShopHeader.vue'
import ShopFooter from '../components/ShopFooter.vue'
import axios from 'axios'
import Utils from '@/lib/utils.js'

export default {
  name: 'OrderStatus',
  components: {
    Header: ShopHeader,
    Footer: ShopFooter
  },
  data: () => ({
    shopName: '',
    basket: {
      slug: '',
      shopName: '',
      couponCode: '',
      companyId: 0,
      items: [],
      tx: ''
    },
    couponCode: '',
    couponError: '',
    statusModal: false,
    status: '',
    currentStatus: {
      title: '',
      message: ''
    },
    order: {},
    tx: ''
  }),
  mounted () {
    document.title = 'wotFood | Order Status'
    const tx = Utils.getQuery('tx')
    if (tx !== '') {
      this.statusModal = true
      this.tx = tx
      this.getDataFromTx(tx)
    }
    window.scroll(0, 0)
    // setTimeout(() => { document.getElementById('mainWrapper').style.display = '' }, 400)
  },
  methods: {
    getDataFromTx (tx) {
      const app = this
      axios.post('/api/shop/get-order-data-by-tx', { tx: tx })
        .then((data) => {
          const parsedData = data.data
          app.basket = parsedData.basket
          app.shopName = app.basket.shopName
          app.order = parsedData.order
          const status = parsedData.order.status
          // alert(JSON.stringify(app.order))
          if (status === 'authorisation-requested-cash' || status === 'authorisation-requested-credit') {
            setTimeout(app.getDataFromTx, 2000, tx)
          } else {
            app.currentStatus.title = ''
            app.currentStatus.message = ''
            app.status = status

            if (status === 'rejected') {
              app.currentStatus.title = '❗Order Rejected'
              app.currentStatus.message = 'Sorry your order has been cancelled because it was rejected by the shop, if you have paid by credit then we will have issued an automated refund, it however may take up to a week to process'
            }
            if (status === 'timeout') {
              app.currentStatus.title = '❗ Order Timed out'
              app.currentStatus.message = 'Sorry your order has been cancelled because it has timed out, if you have paid by credit then you will need to contact the shop for a refund'
            }
            if (status === 'cancelled') {
              app.currentStatus.title = '❗ Order Cancelled'
              app.currentStatus.message = 'Sorry your order has been cancelled, if you have paid by credit then you will need to contact the shop for a refund'
            }
            if (status === 'accepted') {
              app.currentStatus.title = '👍 Confirmed!'
              app.currentStatus.message = 'You order has been accepted, please review the details below'
            }
            app.statusModal = false
          }
        })
        .catch(({ response }) => {
          app.currentStatus.title = '❗Oops something went wrong'
          app.currentStatus.message = "sorry there is some technical issue and we're unable to process your status!"
          app.statusModal = false
        })
    },
    updateBasketCount () {
      this.emitter.emit('basket-set-total', this.basket.items.length)
    },
    calculateSubTotal (item) {
      if (item.mergedOptions.length > 0) {
        let optionTotal = 0
        item.mergedOptions.forEach(sub => {
          optionTotal += sub.price
        })
        return ((item.qty * (item.price + optionTotal)) / 100).toFixed(2)
      } else {
        return ((item.qty * item.price) / 100).toFixed(2)
      }
    },
    prettyPrintTime (x) {
      let t = parseInt(x)
      const mx = (60 * 24)
      if (t > mx) {
        t = t - mx
      }
      const hours = Math.floor(t / 60)
      const minutes = t % 60
      return `${this.doubleZero(hours)}:${this.doubleZero(minutes)}`
    },
    doubleZero (x) {
      return x.toString().padStart(2, '0')
    }
  },
  computed: {
    computeGrandTotal () {
      if (this.order.total) {
        return (this.order.total - this.order.discount) + this.order.shipping
      } else {
        return 0
      }
    }
  }
}
</script>

<style lang="scss">
.checkout-button-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a,
  .checkout-button {
    width: 100%;
    max-width: 300px;
  }

  input {
    width: 300px;
  }
}

.return-button {
  width: 200px;
}

.error {
  color: #ff5c33;
}
</style>
