// eslint-disable-file
export default {
  chunkArray (myArray, chunkSize) {
    let index = 0
    const arrayLength = myArray.length
    const tempArray = []

    for (index = 0; index < arrayLength; index += chunkSize) {
      const myChunk = myArray.slice(index, index + chunkSize)
      tempArray.push(myChunk)
    }
    return tempArray
  },
  getQuery (name) {
    // eslint-disable-next-line
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
    const results = regex.exec(location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
  },
  toTitleCase (str) {
    str = str.toLowerCase().split(' ')
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    return str.join(' ')
  },
  overlap (a, b) {
    const o = []
    a.forEach(x => {
      b.forEach(y => { if (x === y) { o.push(x) } })
    })
    // FIXME: needs distinct applied!
    return o
  },
  isNotInt (x) {
    return (isNaN(x) || x === null || x === '' || x === undefined)
  },
  randBetween (min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
}
