<template>
  <div id="mainWrapper">
  <Header/>
      <div class="row anchor" id="menutop">
        <div class="col contents primary fixed-mobile top flex-2 left-panel hidden-mobile">
          <div class="row" style="position:fixed">
            <div class="col">
             <!-- <ul class="inline-mobile">
                <li
                  v-for="category in shopData.categories" v-bind:key="category.name"
                >
                  <a :href="'#' + category.name">{{category.name }}</a>
                </li>
              </ul> -->
            </div>
          </div>
        </div>
        <div class="col  no-padding primary flex-6">
          <div v-if="getAnnouncement() !== ''" class="row">
            <div class="col no-padding">
              <div class="card focus center">
                <div class="card-inner">
                  <h2>📣 Announcement:</h2>
                  <p v-if="shopData.companyInfo">{{ shopData.companyInfo[0].announcement }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col no-padding">
              <div class="card primary center">
                <div class="card-inner">
                  <h3 class="center" v-if="shopData.companyInfo">{{ shopData.companyInfo[0].name }}</h3>
                  <strong class="center" v-if="shopData.companyInfo">{{ shopData.companyInfo[0].address }}</strong>
                  <p class="center">
                    <strong>Opening Hours</strong>
                  </p>
                  <table class="center hours">
                    <tr v-for="(o, idx) in filterDay('monday')" :key="idx">
                      <td>{{ toTitleCase(o.open_day) }}</td>
                      <td>
                        {{ String(o.open_hour).length === 1 ? `0${String(o.open_hour)}` : String(o.open_hour) }}:{{String(o.open_min).padStart(2, "0")}}
                      </td>
                      <td>
                        {{ String(o.close_hour).padStart(2, "0") }}:{{String(o.close_min).padStart(2, "0")}}
                      </td>
                    </tr>
                    <tr v-for="(o, idx) in filterDay('tuesday')" :key="idx">
                      <td>{{ toTitleCase(o.open_day) }}</td>
                      <td>
                        {{ String(o.open_hour).length === 1 ? `0${String(o.open_hour)}` : String(o.open_hour) }}:{{String(o.open_min).padStart(2, "0")}}
                      </td>
                      <td>
                        {{ String(o.close_hour).padStart(2, "0") }}:{{String(o.close_min).padStart(2, "0")}}
                      </td>
                    </tr>
                    <tr v-for="(o, idx) in filterDay('wednesday')" :key="idx">
                      <td>{{ toTitleCase(o.open_day) }}</td>
                      <td>
                        {{ String(o.open_hour).length === 1 ? `0${String(o.open_hour)}` : String(o.open_hour) }}:{{String(o.open_min).padStart(2, "0")}}
                      </td>
                      <td>
                        {{ String(o.close_hour).padStart(2, "0") }}:{{String(o.close_min).padStart(2, "0")}}
                      </td>
                    </tr>
                    <tr v-for="(o, idx) in filterDay('thursday')" :key="idx">
                      <td>{{ toTitleCase(o.open_day) }}</td>
                      <td>
                        {{ String(o.open_hour).length === 1 ? `0${String(o.open_hour)}` : String(o.open_hour) }}:{{String(o.open_min).padStart(2, "0")}}
                      </td>
                      <td>
                        {{ String(o.close_hour).padStart(2, "0") }}:{{String(o.close_min).padStart(2, "0")}}
                      </td>
                    </tr>
                    <tr v-for="(o, idx) in filterDay('friday')" :key="idx">
                      <td>{{ toTitleCase(o.open_day) }}</td>
                      <td>
                        {{ String(o.open_hour).length === 1 ? `0${String(o.open_hour)}` : String(o.open_hour) }}:{{String(o.open_min).padStart(2, "0")}}
                      </td>
                      <td>
                        {{ String(o.close_hour).padStart(2, "0") }}:{{String(o.close_min).padStart(2, "0")}}
                      </td>
                    </tr>
                    <tr v-for="(o, idx) in filterDay('saturday')" :key="idx">
                      <td>{{ toTitleCase(o.open_day) }}</td>
                      <td>
                        {{ String(o.open_hour).length === 1 ? `0${String(o.open_hour)}` : String(o.open_hour) }}:{{String(o.open_min).padStart(2, "0")}}
                      </td>
                      <td>
                        {{ String(o.close_hour).padStart(2, "0") }}:{{String(o.close_min).padStart(2, "0")}}
                      </td>
                    </tr>
                    <tr v-for="(o, idx) in filterDay('sunday')" :key="idx">
                      <td>{{ toTitleCase(o.open_day) }}</td>
                      <td>
                        {{ String(o.open_hour).length === 1 ? `0${String(o.open_hour)}` : String(o.open_hour) }}:{{String(o.open_min).padStart(2, "0")}}
                      </td>
                      <td>
                        {{ String(o.close_hour).padStart(2, "0") }}:{{String(o.close_min).padStart(2, "0")}}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isLoading">
            <div class="row">
              <div class="col">
              <p class="center">
                <img src="@/assets/images/loader.gif" class="image" style="width:200px" />
              </p>
              </div>
            </div>
          </div>
          <div class="row" v-for="(categoryMeta, ida) in shopData.categories" :key="ida">
            <div class="col no-padding">
              <div class="card primary slim-card-padding">
                <div class="card-inner">
                  <p class="anchor" :id="categoryMeta.name"></p>
                  <p class="center display-name category-name unselectable" @click="onSelectedCategory(categoryMeta.name)">
                    <strong>{{ categoryMeta.name }}</strong> <i :class="[ selectedCategory===categoryMeta.name ? 'fa fa-angle-up cevron' : 'fa fa-angle-down cevron']" />
                  </p>
                  <ul
                    class="list"
                    v-for="(product, idx) in FilterItemsByCategory(categoryMeta.name)"
                    :key="idx"
                  >
                    <li class="list-item clickable" style="min-height: 55px" @click="btnPickItem(product)">
                      <span class="item-name unselectable">{{ product.name }}</span>
                      <span class="item-description unselectable">{{ product.description }}</span>
                      <span class="item-price unselectable">£{{ (product.price/100).toFixed(2) }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="center">
                <a class="button center alternative" @click="scrollBackUp()">
                  <i class="fas fa-hand-point-up" /> scroll back up
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="col contents primary flex-2 hidden-mobile"></div>
      </div>
      <div class="modal" :class="[ModalShowPickItem ? 'open': 'closed']">
        <div class="modal-content">
          <div class="row">
            <div class="col primary">
              <h3>{{ selectedItem.name }}</h3>
            </div>
          </div>
          <div v-if='selectedItem.has_options'>
            <div v-for="(options, idx) in selectedItem.options" :key="idx" class="row">
              <div class="col primary">
                <b>{{ options.title }}</b>({{ prettyPrintOptionKind(options.option_kind) }})
                <span v-for="(item, idi) in options.options" :key="idi">
                  <span style="float:left;cursor:pointer" @click="btnPickOption(item, options)" ><i v-if="hasOptionBeenSelected(item.id)" class="fas fa-check-circle highlight" /> {{ item.name }}</span>
                  <span style="float:right" class="item-price">+£{{ (item.price/100).toFixed(2) }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col primary custom-flex">
                <div
                  class="item-minus unselectable"
                  @click="btnQtyMinus()"
                  >-</div
                >
                <div class="item-basket-qty unselectable"><h2>{{ Qty }}</h2></div>
                <div
                  class="item-plus unselectable"
                  @click="btnQtyPlus()"
                  >+</div
                >
            </div>
          </div>
          <div class="row">
            <div class="col primary">
              <span class="button regular alternative" @click="btnCancelPickItem()" >cancel</span>
            </div>
            <div class="col primary">
              <span class="button regular" @click="btnConfirmPickItem()" >Add £{{((selectedItem.price+selectedOptionsTotal())/100  * Qty).toFixed(2)}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal modal-order" :class="[categoryModal ? 'open' : 'closed']">
          <div class="modal-content">
            <div class="row">
              <div class="col">
                <div class="content content-left">
                  <li
                    v-for="category in shopData.categories" v-bind:key="category.name"
                  >
                    <span class="category-items" @click="gotoCategory(category.name)">{{ category.name }}</span>
                  </li>
                </div>
              </div>
            </div>
          </div>
      </div>

      <div class="modal" :class="[ModalAlertShow ? 'open' : 'closed']">
            <div class="modal-content">
              <div class="row">
                <div class="col primary">
                  <p>{{ ModalAlertMessage }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col primary" style="padding-top: 0">
                  <button class="button center" @click="btnCloseModalAlert">
                    ok
                  </button>
                </div>
              </div>
            </div>
        </div>
      <!--<div class="modal close">
        <div class="modal-content">
          <div class="row">
            <div class="col">
              TEST
            </div>
          </div>
        </div>
      </div> -->
  <Footer/>
  </div>
</template>

<script>
import ShopHeader from '../components/ShopHeader.vue'
import ShopFooter from '../components/ShopFooter.vue'
import axios from 'axios'
import utils from '../lib/utils'
import Cookies from '../lib/cookies.js'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'Contact',
  components: {
    Header: ShopHeader,
    Footer: ShopFooter
  },
  data: () => ({
    sessionId: null,
    shopData: {},
    isLoading: false,
    ModalShowPickItem: false,
    selectedItem: {},
    selectedCategory: '',
    shopSlug: '',
    Qty: 1,
    basket: {
      slug: '',
      shopName: '',
      couponCode: '',
      companyId: 0,
      items: [],
      tx: ''
    },
    isBasketReady: false,
    categoryModal: false,
    ModalAlertShow: false,
    ModalAlertMessage: ''
  }),
  mounted () {
    window.scroll(0, 0)
    this.emitter.on('category-menu-clicked', () => {
      this.categoryModal = !this.categoryModal
    })
    const app = this
    app.isLoading = true
    const sessionId = Cookies.getCookie('session')
    if (sessionId === '') {
      // we need to start a new session
      this.startNewSession()
    } else {
      this.restoreSessionData(sessionId)
    }
    axios.post('/api/shop/get-shop-data', { postcode: this.$route.params.postcode, slug: this.$route.params.name })
      .then((data) => {
        console.log('==> [Shop] loaded all shop meta data')
        app.shopData = data.data
        console.log(data.data)
        app.isLoading = false
        app.shopSlug = `/${this.$route.params.postcode}/${this.$route.params.name}`
        document.title = `wotFood | ${app.shopData.companyInfo[0].name}`
      })
      .catch(({ response }) => {
        app.isLoading = false
        alert(`error: -> ${JSON.stringify(response)}`)
      })
    // setTimeout(() => { document.getElementById('mainWrapper').style.display = '' }, 200)
  },
  methods: {
    btnCloseModalAlert () {
      this.ModalAlertShow = false
      this.ModalAlertMessage = ''
    },
    onSelectedCategory (category) {
      if (category === this.selectedCategory) {
        this.selectedCategory = ''
      } else {
        this.selectedCategory = category
      }
    },
    FilterItemsByCategory (category) {
      if (this.shopData && this.selectedCategory === category) {
        const items = this.shopData.menuData.filter((item) => item.category === category)
        return items
      }
      return []
    },
    getAnnouncement () {
      if (this.shopData.companyInfo && this.shopData.companyInfo[0].announcement) {
        return this.shopData.companyInfo[0].announcement
      }
      return ''
    },
    toTitleCase (str) {
      return utils.toTitleCase(str)
    },
    btnPickItem (p) {
      const app = this
      this.selectedItem.id = p.id
      this.selectedItem.name = p.name
      this.selectedItem.price = p.price
      this.selectedItem.has_options = p.has_options
      this.selectedItem.is_non_discount = p.is_non_discount
      this.Qty = 1
      // TODO: if product has options we should load them up unless already loaded
      if (this.selectedItem.has_options) {
        axios.post('/api/shop/get-product-options', { productId: p.id })
          .then((data) => {
            const parsedOptions = data.data.map(x => {
              return { title: x.title, option_kind: x.option_kind, options: JSON.parse(x.options) }
            })
            app.selectedItem.options = parsedOptions
            app.ModalShowPickItem = true
          })
          .catch(({ response }) => {
            alert(`error: -> ${JSON.stringify(response)}`)
          })
      } else {
        this.ModalShowPickItem = true
      }
    },
    btnPickOption (option, group) {
      if (!this.selectedItem.selectedOptions) {
        this.selectedItem.selectedOptions = []
      }
      if (group.option_kind === 'pick-one') {
        const idsInGroup = group.options.map((x) => {
          return x.id
        })
        // remove all ids in this group because the selected ID has to be unique
        // this.selectedItem.selectedOptions = this.selectedItem.selectedOptions.filter((x) => {
        //   idsInGroup.some(y => y !== x)
        // })
        idsInGroup.forEach(ids => {
          this.selectedItem.selectedOptions = this.selectedItem.selectedOptions.filter((x) => {
            return x !== ids
          })
        })
        this.selectedItem.selectedOptions.push(option.id)
      } else {
        // if the id already exists then remove it from the list
        if (this.selectedItem.selectedOptions.filter((x) => option.id === x).length > 0) {
          this.selectedItem.selectedOptions = this.selectedItem.selectedOptions.filter((x) => x !== option.id)
        } else {
          this.selectedItem.selectedOptions.push(option.id)
        }
      }
      // console.log(this.selectedItem.selectedOptions)
    },
    btnCancelPickItem () {
      this.ModalShowPickItem = false
      if (this.selectedItem.selectedOptions) {
        this.selectedItem.selectedOptions = null
      }
    },
    btnConfirmPickItem () {
      /*
        IF there are extra options make sure that all "pick one" sections are actually selected
        otherwise promt the user to select one and bail.

        For the second check make sure the current basket DOES not belong to another shop, if it
        does just warn them and bail
      */
      if (!this.isBasketReady) {
        this.ModalAlertMessage = 'Sorry try again later'
        this.ModalAlertShow = true
        return
      }
      const missing = []
      if (this.selectedItem.has_options) {
        // check at least 1 from each "pick-one" group has been selected
        this.selectedItem.options.forEach(option => {
          if (option.option_kind === 'pick-one') {
            const ids = option.options.map(x => x.id)
            if (utils.overlap(ids, (this.selectedItem.selectedOptions || [])).length === 0) {
              missing.push(option.title)
            }
          }
        })
      }
      if (missing.length > 0) {
        this.ModalAlertMessage = `Please select one (${missing[0]}) option`
        this.ModalAlertShow = true
      } else {
        if (this.basket.slug === '' || this.basket.items.length === 0) {
          this.basket.slug = this.shopSlug
          this.basket.shopName = this.shopData.companyInfo[0].name
          this.basket.companyId = this.shopData.companyInfo[0].id
        }
        if (this.basket.slug === this.shopSlug) {
          // add this item to the basket (qty + options)
          // compute the total and update the basket count
          // make sure to save the basket data to the session
          if (this.selectedItem.has_options) {
            this.basket.items.push({
              qty: this.Qty,
              uid: uuidv4(),
              id: this.selectedItem.id,
              price: this.selectedItem.price,
              name: this.selectedItem.name,
              is_non_discount: this.selectedItem.is_non_discount,
              options: (this.selectedItem.selectedOptions || []),
              mergedOptions: this.mergeOptionData(this.selectedItem.selectedOptions)
            })
          } else {
            // this is a normal we must first check if this item has been already added
            // if so we just update the qty:
            let isExisting = false
            this.basket.items.forEach(item => {
              if (item.name === this.selectedItem.name) {
                item.qty += this.Qty
                isExisting = true
              }
            })
            if (!isExisting) {
              this.basket.items.push({
                qty: this.Qty,
                uid: uuidv4(),
                id: this.selectedItem.id,
                price: this.selectedItem.price,
                name: this.selectedItem.name,
                is_non_discount: this.selectedItem.is_non_discount,
                options: [],
                mergedOptions: []
              })
            }
          }
          this.saveSession()
          this.emitter.emit('basket-set-total', this.basket.items.length)
          this.btnCancelPickItem()
        } else {
          this.ModalAlertMessage = `Sorry you have pending items from ${this.basket.shopName}`
          this.ModalAlertShow = true
        }
      }
    },
    btnQtyMinus () {
      if (this.Qty === 1) {
        return
      }
      this.Qty -= 1
    },
    btnQtyPlus () {
      this.Qty += 1
    },
    prettyPrintOptionKind (x) {
      if (x === 'pick-one') {
        return 'choose one'
      }
      return 'choose any'
    },
    mergeOptionData (selected) {
      if (selected && this.selectedItem.options) {
        const merged = []
        this.selectedItem.options.forEach(option => {
          const title = option.title
          option.options.forEach(subOps => {
            if (selected.some(item => item === subOps.id)) {
              merged.push({
                id: subOps.id,
                title: title,
                name: subOps.name,
                price: subOps.price
              })
            }
          })
        })
        return merged
      }
      return []
    },
    hasOptionBeenSelected (x) {
      if (!this.selectedItem.selectedOptions) { return '' }
      if (this.selectedItem.selectedOptions.filter((i) => i === x).length > 0) {
        return true
      }
      return false
    },
    selectedOptionsTotal () {
      if (this.selectedItem.options && this.selectedItem.selectedOptions && this.selectedItem.selectedOptions.length > 0) {
        // compute the selected options sub-total
        let total = 0
        this.selectedItem.options.forEach(i => {
          const subTotal = i.options.filter((x) => {
            return this.selectedItem.selectedOptions.some((y) => x.id === y)
          }).map((x) => x.price)
          total += subTotal.reduce((a, b) => a + b, 0)
        })
        return total
      }
      return 0
    },
    startNewSession () {
      const app = this
      Cookies.deleteCookie('session')
      console.log('==> [Shop] no session found, starting new session...')
      axios.post('/api/shop/get-new-session', { data: {} })
        .then((data) => {
          console.log(`==> [Shop] new session created -> ${data.data}`)
          app.sessionId = data.data
          app.isBasketReady = true
          Cookies.setCookie('session', data.data, 1)
        })
        .catch(({ response }) => {
          alert(`error: -> ${JSON.stringify(response)}`)
        })
    },
    restoreSessionData (sessionId) {
      // keep in mind the session may have expired in which case we will need to
      // create a new session and update the cookie
      const app = this
      this.sessionId = sessionId
      console.log(`==> [Shop] restoring session data for (${sessionId})`)
      axios.post('/api/shop/get-session', { session: this.sessionId })
        .then((data) => {
          const parsedBasket = JSON.parse(data.data)
          if (parsedBasket.basket) {
            app.basket = parsedBasket.basket
            app.isBasketReady = true
            app.emitter.emit('basket-set-total', app.basket.items.length)
          } else {
            app.isBasketReady = true
            app.emitter.emit('basket-set-total', 0)
          }
        })
        .catch(({ response }) => {
          console.log('==> [Shop] unable to restore session it may be expired')
          app.isBasketReady = true
          app.startNewSession()
        })
    },
    saveSession () {
      console.log(`==> [Shop] saving session (${this.sessionId})`)
      axios.post('/api/shop/update-session', { session: this.sessionId, data: { basket: this.basket } })
        .then((data) => {
          console.log('==> [Shop] session saved')
        })
        .catch(({ response }) => {
          alert(`error: -> ${JSON.stringify(response)}`)
        })
    },
    gotoCategory (category) {
      const anchor = document.getElementById(`${category}`)
      if (anchor) {
        anchor.scrollIntoView()
      }
      this.categoryModal = false
    },
    scrollBackUp () {
      window.scroll(0, 0)
    },
    filterDay (y) {
      if (this.shopData.openingTimes) {
        return this.shopData.openingTimes.filter(x => x.open_day === y).sort((a, b) => a.open_hour - b.open_hour)
      }
      return []
    }
  }

}
</script>

<style lang="scss">
#shop {
  ul {
    margin: 0px;

    li {
      .item-qty {
        margin-right: 5px;
      }
    }
  }

  .announcement {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
    max-width: 600px;

    h2 {
      font-size: 30px !important;
      word-break: break-all !important;
    }
  }

  .display-name::before {
    display: block;
    content: " ";
    margin-top: -280px;
    height: 280px;
    visibility: hidden;
    pointer-events: none;
  }
}

p.anchor {
  display: block;
  position: relative;
  top: -180px;
}

.category-items {
  color: var(--accent-color);
}
.category-items:hover {
  cursor: pointer;
}
.category-name:hover {
  cursor: pointer;
}

.content-left {
  text-align: left;
}

@media(max-width: 767px) {
  .hidden-mobile {
    display: none !important;
  }
}

.custom-flex {
  flex-direction: row !important;
  justify-content: center !important;
  align-items: baseline !important;
}

.item-minus,
.item-plus {
    color: #f58427;
    font-size: 40px;
    border-radius: 12px;
    padding-left: 6px;
    padding-right: 6px;
}

.item-minus:hover,
.item-plus:hover {
    opacity: 1.0;
    transition: 0.25s opacity, 0.25s box-shadow;
    box-shadow: 0px 0px 20px 0px #0002;
    cursor: pointer;
}

.slim-card-padding {
  padding-top: 1px;
  padding-bottom: 1px;
}

.cevron {
  float: right;
  padding-right: 7px;
}

</style>
