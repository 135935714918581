<template>
  <div id="mainWrapper">
  <Header hide-menu-bar="true"/>
  <div id="home">
    <section :class="heroCss">
      <div class="container">
      <div class="row">
        <div class="col">
          <div class="card primary">
            <div class="card-inner">
              <router-link type="a" to="/">
                <img src="@/assets/images/wot-logo.svg" alt="wotFood - web ordering technology"/>
              </router-link>
              <div class="input-form">
                <div class="input">
                  <input
                      class="center-mobile"
                      type="text"
                      placeholder="what's your postcode?"
                      @keyup.enter="btnFind()"
                      v-model="postcode"
                  />
                </div>
                  <input
                      type="submit"
                      value="Search"
                      class="button primary regular"
                      @click="btnFind()"
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
  </div>
  <Footer/>
  </div>
</template>

<script>
// import axios from 'axios'
import ShopHeader from '../components/ShopHeader.vue'
import ShopFooter from '../components/ShopFooter.vue'
import Utils from '../lib/utils'
// import axios from 'axios'

export default {
  name: 'Home',
  components: {
    Header: ShopHeader,
    Footer: ShopFooter
  },
  data: () => ({
    postcode: '',
    isError: false,
    heroCss: 'home-hero'
  }),
  mounted () {
    // document.getElementsByClassName('home-hero')[0].style.backgroundImage = `url(../assets/images/${picked})`
    // setTimeout(() => { document.getElementById('mainWrapper').style.display = '' }, 400)
    this.getHeroCss()
  },
  created () {
    /*
    axios.post('/api/echo', { message: 'hello world!' })
      .then((data) => {
        alert(data.data)
      })
      .catch(({ response }) => {
        alert(`error: -> ${JSON.stringify(response)}`)
      })
    */
    document.title = 'wotFood | web ordering technology'
  },
  methods: {
    btnFind () {
      if (this.postcode !== '') {
        this.$router.push({
          name: 'search',
          query: {
            postcode: this.postcode
          }
        })
      }
    },
    getHeroCss () {
      const images = ['indian', 'kebabs', 'pizza', 'chinese', 'chips', 'sushi']
      const picked = images[Utils.randBetween(0, images.length - 1)]
      this.heroCss = `home-hero hero-${picked}`
    }
  }
}
</script>

<style lang="scss">
#home {
  background-color: #ffffff;
  margin-top: 20px;
  padding-bottom: 60px;

  .hero-indian {
    background-image: url(../assets/images/various-indian.jpg) !important;
  }
  .hero-kebabs {
    background-image: url(../assets/images/various-kebabs.jpg) !important;
  }
  .hero-pizza {
    background-image: url(../assets/images/various-pizza.jpg) !important;
  }
  .hero-chinese {
    background-image: url(../assets/images/various-chinese.jpg) !important;
  }
  .hero-chips {
    background-image: url(../assets/images/various-chips.jpg) !important;
  }
  .hero-sushi {
    background-image: url(../assets/images/various-sushi.jpg) !important;
  }

  .home-hero {
    background-image: url(../assets/images/various-none.jpg);
    background-color: #333;
    background-size: cover;
    -webkit-background-size: cover;
    background-position: center center;
    max-height: 400px;
    padding: 15px;
    .container {
      background-color: transparent;
      .col {
        flex-direction: row;
        justify-content: center;
      }
    }
    .card {
      width: 100%;
      max-width: 500px;
      @media (max-width: 798px) {
        display: block;
        margin: 0 auto;
      }
      .card-inner {
        padding: 20px;
        align-items: center;
        display: flex;
        flex-direction: column;
        img {
          max-width: 100%;
          width: 300px;
        }
        .input-form {
          display: flex;
          flex-direction: column;
          .button {
            margin: 0px !important;
          }
        }
      }
    }
  }
  .error {
    margin-top: 10px;
    margin-bottom: 0px;
    text-align: center;
  }

  #shopCard {
    .card-inner {
      height: 300px;

      .card-image {
        padding-bottom: calc(80% * 2 / 3);
      }
    }
  }

  .row-grid {
    background-color: white;
  }
}
</style>
