<template>
  <Header hide-menu-bar="true" />
  <div class="container">
    <div class="row">
      <div class="col">
    <h1>Privacy Policy</h1>
    <p>The trading name wotFood and its subsidiaries are all fully owned by Systems Based Solutions (SBS) Ltd.</p>
    <p>“Us”, “we” and “our” and means SBS Ltd.</p>
    <p>Our privacy principles outline our commitments to you, our customers, clients, and prospective customers, in relation to how SBS Ltd will handle and safeguard your personal data. We take our responsibilities for your privacy very seriously and SBS Ltd companies and our third party processors may only process your personal data in accordance with these principles and our regulatory responsibilities, and our employees are trained against these principles.</p>
    <h4>1. <b>Collecting and using your Personal Data</b></h4>
    <p>We will collect the personal data we need in order to deliver the products and services that you have requested from us and we will only do so with your consent, for the performance of a sale or purchase, or, if you have provided us with your consent, to send you marketing communications. We will process your personal data in a fair and lawful way and we will be clear and transparent about how we process your personal data at SBS Ltd.</p>
    <h4>2. <b>Processing and Retaining Your Personal Data</b></h4>
    <p>Except where required otherwise by applicable law, we will only keep your personal data as long as is necessary to facilitate the delivery of sales, products and services to you. In storing your data we will only keep the parts of it which are vital in the provision our sales, products and services, or when we are required to satisfy our legal and regulatory obligations. When we no longer require your data for these purposes, your data will be deleted.</p>
    <h4>3. <b>Security and Confidentiality</b></h4>
    <p>Except where required otherwise by applicable law, we will keep your personal data secure and confidential; ensuring that access to your personal data will be limited appropriately only to those who need it to conduct business activities. We adhere to industry standards for technology, security and use tested administrative, technical and physical security measures to protect your personal data from unauthorised access, use, modification, disclosure or destruction. We also ensure that our employees undertake training in order to uphold these principles and commitments.</p>
    <h4>4. <b>Data Sharing</b></h4>
    <p>We only share your personal data with third parties in accordance to our Privacy Policy, or where it has either been authorised by you. We do not share or use your details for third party marketing purposes unless you have expressly consented. Where your personal data is transferred outside of the European Union, in order to deliver the sales, products or services that you require, for example with our third party processors or partners, we will ensure that it is treated in accordance to local laws and regulations. Where we share your personal data with our processors, we also ensure that they adopt industry standard data security measures.</p>
    <h4>5. <b>Data Access and Your Rights</b></h4>
    <p>SBS Ltd will uphold your rights about how your personal data is used. If you request access to your personal data, we will provide this to you in accordance to your rights and local laws and regulations. We will also inform you about how your personal data is processed by SBS Ltd. If you think your data is inaccurate you can ask us to change it. In accordance with local laws and regulations, where you feel that there is no longer a valid purpose for us retaining your data, you may request us to erase your personal data. You may also change your mind about how we can use your personal data by withdrawing your processing consent at any time.</p>
    <h4>6. <b>Accountability</b></h4>
    <p>We will endeavour to uphold these principles to the best of our ability at all times and hold ourselves accountable for doing so. We will ensure that our employees, contractors, partners and third party processors will adhere to these principles and commitments.</p>
    <p>Some countries may have differing data protection legislation, where this is the case SBS Ltd will meet its local legal and regulatory obligations.</p>
    <p>For further information on how SBS Ltd processes your personal data please refer to your sale, product or service terms and conditions. You can contact us via the details below.</p>
    <p>Any complaints should be addressed in the first instance to:</p>
    <ul>
        <li>
            <b>By Email:</b><br/>
            support@wotfood.co.uk<br/><br/>
        </li>
        <li>
            <b>By Post:</b><br/>
            wotFood Customer Care<br/>
            Systems Based Solutions Ltd<br/>
            Suite 197, 4 Blenheim Court, Peppercorn Close,<br/>
            Peterborough, PE1 2DU
        </li>
    </ul>
      </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import ShopHeader from '../components/ShopHeader.vue'
import ShopFooter from '../components/ShopFooter.vue'

export default {
  name: 'Privacy',
  components: {
    Header: ShopHeader,
    Footer: ShopFooter
  }
}
</script>
