<template>
  <Header hide-menu-bar="true" />
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Generate New Password Hash</h1>
        <input type="text" v-model="password" v-on:keyup.enter="onEnter">
        <h3>{{ hash }}</h3>
      </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import ShopHeader from '../components/ShopHeader.vue'
import ShopFooter from '../components/ShopFooter.vue'
import axios from 'axios'

export default {
  name: 'Contact',
  components: {
    Header: ShopHeader,
    Footer: ShopFooter
  },
  data: () => ({
    password: '',
    hash: ''
  }),
  methods: {
    onEnter () {
      axios.post('/api/user/generate-password-hash', { password: this.password })
        .then((data) => {
          this.hash = data.data
        })
        .catch(({ response }) => {
          alert(`error: -> ${JSON.stringify(response)}`)
        })
    }
  }
}
</script>
