<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="content">
              <router-link to="/">
                <img src="@/assets/images/wot-logo.svg" alt="wotFood - web ordering technology" width="100px">
              </router-link>
              <ul>
                <li><router-link tag="a" to="/terms">Terms & Conditions</router-link></li>
                <li><router-link tag="a" to="/privacy">Privacy Policy</router-link></li>
                <li><router-link tag="a" to="/cookies">Cookies</router-link></li>
                <li><router-link tag="a" to="/contact">Contact Us</router-link></li>
              </ul>
          </div>
           <p class="version">client: v0.9.4 | server: v{{ version }}</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    version: ''
  }),
  created () {
    axios.post('/api/shop/get-version')
      .then((data) => {
        this.$data.version = data.data
      })
      .catch(({ response }) => {
        console(`error: -> ${JSON.stringify(response)}`)
      })
  }
}
</script>

<style lang="scss">
footer {
    img {
      width: 100px;
    }

    background-color: #ffffff;
    width: 100%;
    .container, .row, .col {
        background-color: #ffffff;
    }

    .col {
        display: flex;
        align-items: center;
        .content {
          display: flex;
          align-items: center;
          @media(max-width: 500px) {
            flex-direction: column;
          }
        }
    }

    ul {
        display:  inline-flex;
        li {
            font-size: 14px;
            margin-right: 10px;
            a {
                &:hover {
                    color: var(--negative-bg-color);
                }
            }
            :last-child {
                margin-right: 0px;
            }
        }
    }

    .version {
      font-size:12px;
    }
}
</style>
