<template>
  <div class="card center shopCard">
    <div
      class="card-inner"
      v-bind:style="{ backgroundImage: 'url(' + shopBackground + ')' }"
      style="background-size: cover"
    >
      <div v-bind:class="{ overlay: overlay }" v-bind:title="getHoverText"></div>
      <div class="card-image"></div>
      <img :src="shopLogo" class="logo" />
      <h4>{{ shopName }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shopName: {
      type: String,
      required: true
    },
    shopCity: {
      type: String,
      required: true
    },
    shopSlug: {
      type: String,
      required: true
    },
    shopLogo: {
      type: String,
      required: true
    },
    shopBackground: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean
    },
    isRegistered: {
      type: Boolean
    }
  },
  methods: {
    goToShop () {
      if (!this.isOpen || this.isUnavailable) {
        return
      }
      this.$router.push({ name: 'ShopHome' })
    }
  },
  computed: {
    overlay () {
      return !this.isOpen || !this.isRegistered
    },
    getHoverText () {
      return !this.isRegistered ? 'not registered with wotFood yet' : this.shopName
    }
  }
}
</script>

<style lang="scss">
.shopCard {
  overflow: hidden;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 800px) {
    width: 100%;
    max-width: 600px;
    padding: 5px 15px;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background-color: grey;
    opacity: 0.8;
    position: absolute;
  }
  height: 100%;
  h4 {
    padding: 10px;
    color: white;
  }
  .card-inner {
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  .logo {
    border-radius: 50%;
    width: 70px;
    height: 70px;
  }
}
</style>
