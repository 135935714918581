<template>
  <div id="mainWrapper">
  <Header hide-menu-bar="true"/>
      <div class="container">
        <div class="row">
          <div class="col no-padding alternative flex-6">
            <div class="row alternative">
              <div class="col flex-4">
                <div class="row" v-if="isLoading">
                  <div class="col">
                    <div class="card primary">
                      <div class="card-inner">
                        <p class="center">
                          <img src="assets/images/loader.gif" />
                          <span v-if="basketLoadingError">
                            <br/>
                            Sorry there seems to be some issue in loading your basket, please try refreshing the page and see if that helps?
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="!isLoading">
                  <div class="col">
                    <div class="card focus center">
                      <div class="card-inner">
                        <b v-if="shopStatus=='closed'">
                          <br/>
                          💬 Sorry we're closed at the moment!
                          <br/>
                          <br/>
                        </b>
                        <b v-if="shopStatus=='suspended'">
                          <br/>
                          💬 Sorry this shop is not able to serve any customers at this time!
                          <br/>
                          <br/>
                        </b>
                        <b v-if="shopStatus=='not-registered'">
                          <br/>
                          💬 Sorry this shop is not yet registered with wotFood!
                          <br/>
                          <br/>
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="!isLoading">
                  <div class="col no-padding">
                    <div class="card primary">
                      <div class="card-inner">
                        <div class="row">
                          <div class="col no-padding">
                            <p class="center">
                              <strong v-if="shopName != ''">Continue shopping at <br/><router-link :to="basket.slug">{{ shopName }}</router-link></strong>
                              <strong v-if="shopName == '' && basket.items.length == 0">Oops your basket empty!</strong>
                            </p>
                          </div>
                        </div>
                        <p class="center">
                          <table class="table">
                            <tr v-for="(item, idx) in basket.items" :key="idx">
                              <td>{{ item.qty }}x </td>
                              <td>
                                {{ item.name }} <small>@£{{ (item.price/100).toFixed(2) }}</small>
                                <span v-if="item.mergedOptions.length > 0" style="font-size:small">
                                  <br/>
                                  <span v-for="(subOps, idx) in item.mergedOptions" :key="idx">
                                     + (£{{ (subOps.price/100).toFixed(2) }}) <b>{{ subOps.title }}:</b> {{ subOps.name }}<br/>
                                  </span>
                                </span>
                              </td>
                              <td><span class="item-price unselectable">£{{ calculateSubTotal(item) }}</span></td>
                              <td><span style="color:#f77960;cursor:pointer" @click="btnRemove(item.uid)"><i class="fas fa-trash" /></span></td>
                            </tr>
                          </table>
                        </p>
                        <div v-if="basket.items.length > 0" class="row">
                           <div class="col no-padding">
                              <p class="center">
                                <button class="button center" @click="btnClearBasket">
                                  <i class="fas fa-trash" /> Clear Basket
                                </button>
                              </p>
                           </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if=" calculateGrandTotal() > 0 && shopStatus=='open' && !isLoading">
                  <div class="col no-padding">
                    <div class="card primary">
                      <div class="card-inner">
                        <p class="center" v-if="discount > 0">
                          <i class="fas fa-check-circle" /> &nbsp; Discount: £{{ (discount/100).toFixed(2) }}
                          ({{ couponCode.toUpperCase() }}) &nbsp;<i class="fas fa-trash" style="color:#f77960;cursor:pointer" @click="btnClearCoupon()"></i>
                        </p>
                        <p class="center">
                          <strong>Total: £{{ calculateGrandTotal() }}</strong>
                        </p>
                        <div v-if="!isCouponLoading" class="checkout-button-wrap" style="padding: 10px">
                          <input
                            v-if="discount===0"
                            name="Coupon"
                            type="text"
                            class="input"
                            placeholder="Coupon"
                            v-model="couponCode"
                          />
                          <button
                            v-if="discount===0"
                            class="button center checkout-button alternative"
                            @click="btnApplyCoupon"
                          >
                            Apply Coupon
                          </button>
                          <p class="error" v-if="couponError !== ''">{{ couponError }}</p>
                        </div>
                        <div v-if="isCouponLoading" class="checkout-button-wrap" style="padding: 10px">
                          <img src="assets/images/loader.gif" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if=" calculateGrandTotal() > 0 && !isLoading">
                  <div class="col no-padding">
                    <div class="card primary">
                      <div class="card-inner">
                        <div v-if="shopStatus=='open'" class="checkout-button-wrap" style="padding: 10px">
                            <button
                              class="button center checkout-button alternative"
                              @click="btnCheckoutAsGuest"
                            >
                              <i class="fas fa-thumbs-up" /> Checkout
                            </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal" :class="[modalClearBasket ? 'open' : 'closed']">
            <div class="modal-content">
              <div class="row">
                <div class="col primary">
                  <p>I confirm I want to clear my basket.</p>
                </div>
              </div>
              <div class="row">
                <div class="col primary" style="padding-top: 0">
                  <button class="button center alternative" @click="btnCancelClear">
                    Cancel
                  </button>
                </div>
                <div class="col primary" style="padding-top: 0">
                  <button class="button center" @click="btnConfirmClearBasket">
                    Clear Basket
                  </button>
                </div>
              </div>
            </div>
        </div>
        <div class="modal" :class="[modalClearCoupon ? 'open' : 'closed']">
            <div class="modal-content">
              <div class="row">
                <div class="col primary">
                  <p>Are you sure you want to remove this coupon?</p>
                </div>
              </div>
              <div class="row">
                <div class="col primary" style="padding-top: 0">
                  <button class="button center alternative" @click="btnCancelClear">
                    Cancel
                  </button>
                </div>
                <div class="col primary" style="padding-top: 0">
                  <button class="button center" @click="btnConfirmClearCoupon">
                    Remove Coupon
                  </button>
                </div>
              </div>
            </div>
        </div>
        </div>
      </div>
  <Footer/>
  </div>
</template>

<script>
import ShopHeader from '../components/ShopHeader.vue'
import ShopFooter from '../components/ShopFooter.vue'
import Cookies from '../lib/cookies.js'
import axios from 'axios'

export default {
  name: 'Basket',
  components: {
    Header: ShopHeader,
    Footer: ShopFooter
  },
  data: () => ({
    shopName: '',
    sessionId: '',
    basket: {
      slug: '',
      shopName: '',
      couponCode: '',
      companyId: 0,
      items: [],
      tx: ''
    },
    modalClearBasket: false,
    modalClearCoupon: false,
    discount: 0,
    couponCode: '',
    couponError: '',
    isCouponLoading: false,
    shopStatus: '',
    isLoading: true,
    basketLoadingError: false
  }),
  mounted () {
    document.title = 'wotFood | Basket'
    window.scroll(0, 0)
    this.restoreBasetFromSession()
  },
  methods: {
    restoreBasetFromSession () {
      const app = this
      const sessionId = Cookies.getCookie('session')
      if (sessionId !== '') {
        console.log(`==> [Basket] trying to restore session data for (${sessionId})`)
        this.sessionId = sessionId
        axios.post('/api/shop/get-session', { session: sessionId })
          .then((data) => {
            const parsedBasket = JSON.parse(data.data)
            if (parsedBasket.basket) {
              app.basket = parsedBasket.basket
              app.shopName = app.basket.shopName
              app.couponCode = app.basket.couponCode
              if (app.couponCode !== '') {
                app.btnApplyCoupon()
              }
              app.getShopStatus()
            }
            app.isLoading = false
          })
          .catch(({ response }) => {
            console.log('==> [Basket] unable to restore session it may be expired')
            console.log(`==> [Basket] Error: ${response}`)
            app.isLoading = false
          })
      } else {
        this.basketLoadingError = true
      }
    },
    updateBasketCount () {
      this.emitter.emit('basket-set-total', this.basket.items.length)
    },
    saveSession () {
      console.log('==> [Basket] saving current session')
      axios.post('/api/shop/update-session', { session: this.sessionId, data: { basket: this.basket } })
        .then((data) => {
          console.log('==> [Shop] session saved')
        })
        .catch(({ response }) => {
          alert(`error: -> ${JSON.stringify(response)}`)
        })
    },
    btnRemove (uid) {
      this.basket.items = this.basket.items.filter((x) => {
        return x.uid !== uid
      })
      this.couponCode = ''
      this.discount = 0
      this.basket.couponCode = ''
      this.updateBasketCount()
      this.saveSession()
    },
    btnClearBasket () {
      this.modalClearBasket = true
    },
    btnClearCoupon () {
      this.modalClearCoupon = true
    },
    btnCancelClear () {
      this.modalClearBasket = false
      this.modalClearCoupon = false
    },
    btnConfirmClearBasket () {
      this.basket.items = []
      this.couponCode = ''
      this.discount = 0
      this.basket.couponCode = ''
      this.updateBasketCount()
      this.saveSession()
      this.modalClearBasket = false
    },
    btnApplyCoupon () {
      const app = this
      this.isCouponLoading = true
      this.couponError = ''
      this.discount = 0
      if (this.couponCode === '') {
        this.couponError = 'please enter a coupon code'
        this.isCouponLoading = false
      } else {
        const payload = {
          session: this.sessionId,
          code: this.couponCode,
          companyId: this.basket.companyId
        }
        axios.post('/api/shop/apply-discount', payload)
          .then((data) => {
            app.discount = data.data.discount
            app.basket.couponCode = app.couponCode
            app.saveSession()
            this.isCouponLoading = false
          })
          .catch(({ response }) => {
            app.couponError = response.data
            app.basket.couponCode = app.couponCode
            app.saveSession()
            this.isCouponLoading = false
          })
      }
    },
    calculateSubTotal (item) {
      if (item.mergedOptions.length > 0) {
        let optionTotal = 0
        item.mergedOptions.forEach(sub => {
          optionTotal += sub.price
        })
        return ((item.qty * (item.price + optionTotal)) / 100).toFixed(2)
      } else {
        return ((item.qty * item.price) / 100).toFixed(2)
      }
    },
    calculateGrandTotal () {
      let total = 0
      this.basket.items.forEach(item => {
        if (item.mergedOptions.length > 0) {
          let optionTotal = 0
          item.mergedOptions.forEach(sub => {
            optionTotal += sub.price
          })
          total += ((item.qty * (item.price + optionTotal)))
        } else {
          total += ((item.qty * item.price))
        }
      })
      return ((total - this.discount) / 100).toFixed(2)
    },
    getShopStatus () {
      axios.post('/api/shop/get-shop-status', { companyId: this.basket.companyId })
        .then((data) => {
          this.shopStatus = data.data
        })
        .catch(({ response }) => {
          alert(JSON.stringify(response))
        })
    },
    btnCheckoutAsGuest () {
      this.$router.push('/checkout')
    },
    btnConfirmClearCoupon () {
      this.couponCode = ''
      this.discount = 0
      this.basket.couponCode = ''
      this.saveSession()
      this.modalClearCoupon = false
    }
  }
}
</script>

<style lang="scss">
.checkout-button-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a,
  .checkout-button {
    width: 100%;
    max-width: 300px;
  }

  input {
    width: 300px;
  }
}

.return-button {
  width: 200px;
}

.error {
  color: #ff5c33;
}
</style>
