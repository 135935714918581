<template>
  <div id="mainWrapper">
  <Header hide-menu-bar="true"/>
  <div id="search">
    <div class="container">
      <div class="row">
        <div class="col no-padding primary flex-4 search-results">
          <div class="row alternative">
            <div class="col"></div>
            <div class="col flex-4">
              <h2 class="center">Restaurants near {{ getPostcode }}</h2>
            </div>
            <div class="col"></div>
          </div>
          <div class="row alternative">
            <div class="col"></div>
            <p class="error">{{ errorMessage }}</p>
            <div class="col"></div>
          </div>
          <div class="alternative" v-for="(shop, idx) in getShops" v-bind:key="idx">
            <div class="row-grid">
              <div class="col no-padding" v-for="(s, idx) in shop" v-bind:key="idx">
                <ShopCard
                  :shopName="s.name"
                  :shopCity="s.city"
                  :shopSlug="s.slug"
                  :shopLogo="s.logo"
                  :shopBackground="getBackgroundImage(idx, s.vertical)"
                  :isOpen="s.is_open"
                  :isRegistered="s.is_registered"
                  @click="goToShop(s)"
                />
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
  </div>
</template>

<script>
import ShopHeader from '../components/ShopHeader.vue'
import ShopFooter from '../components/ShopFooter.vue'
import ShopCard from '../components/ShopCard.vue'
import Utils from '@/lib/utils.js'
import indianImage1 from '@/assets/images/indian-image1.jpg'
import indianImage2 from '@/assets/images/indian-image2.jpg'
import indianImage3 from '@/assets/images/indian-image3.jpg'
import chipsImage1 from '@/assets/images/chips-image1.jpg'
import chipsImage2 from '@/assets/images/chips-image2.jpg'
import chipsImage3 from '@/assets/images/chips-image3.jpg'
import axios from 'axios'

export default {
  name: 'Search',
  components: {
    Header: ShopHeader,
    Footer: ShopFooter,
    ShopCard: ShopCard
  },
  data: () => ({
    postcode: '',
    shops: [],
    errorMessage: '',
    vertical: {
      indian: [indianImage1, indianImage2, indianImage3],
      chips: [chipsImage1, chipsImage2, chipsImage3]
    }
  }),
  computed: {
    getShops () {
      return Utils.chunkArray(this.shops, 3)
    },
    getPostcode () {
      if (this.postcode && this.postcode !== '') {
        return this.postcode.toUpperCase().substring(0, 3)
      }
      return ''
    }
  },
  methods: {
    getBackgroundImage (idx, vertical) {
      switch (idx) {
        case 0:
          return this.vertical[vertical][0]
        case 1:
          return this.vertical[vertical][1]
        case 2:
          return this.vertical[vertical][2]
      }
    },
    goToShop (x) {
      this.$router.push({
        name: 'shop',
        params: {
          postcode: x.city,
          name: x.slug
        }
      })
    }
  },
  mounted () {
    const postcode = Utils.getQuery('postcode')
    const app = this
    this.errorMesage = 'shiiit'
    this.postcode = postcode
    if (postcode !== null && postcode !== '') {
      axios.post('/api/shop/find-shops-by-postcode', { postcode: postcode })
        .then((data) => {
          app.shops = data.data
        })
        .catch(({ response }) => {
          if (response.status === 404 || response.status === 400) {
            this.errorMessage = response.data
          } else {
            this.errorMessage = 'Sorry something went wrong!'
          }
        })
    }
    // setTimeout(() => { document.getElementById('mainWrapper').style.display = '' }, 200)
  }
}
</script>

<style lang="scss">
#search {
  padding-top: 25px;
  .search-results {
    background: var(--alternative-bg-color);
  }
}

.error {
  color: #ff5c33;
}
</style>
