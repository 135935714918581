<template>
  <Header hide-menu-bar="true"/>
  <div class="container">
    <div class="row">
      <div class="col">

        <h1>Terms and Conditions</h1>
                <small>Version 2.0 March 2021</small>

                <p>The trading name wotFood, its domain names, subdomains and its subsidiaries are all fully owned by Systems Based Solutions (SBS) Ltd.</p>
                <p>“Us”, “we” and “our” and means SBS Ltd.</p>
                <p>“You” refers to anyone viewing or using our website.</p>
                <p>Welcome to our website. By browsing our website and using its services, you agree to comply with these terms of use and our privacy policy, the documents that govern this website and your use of it. If you object to any of the terms in this document or our privacy policy, please do not use our website.</p>
                <p>We may change our terms and conditions from time-to-time without notice, so please ensure you carefully read over these terms and conditions on a regular basis. Any new version of this document will supersede this version.</p>

                <h4>Our Service</h4>
                <p>We are an online (web based) platform acting as an interface between you, the consumer, and the food providers listed on our website (such as takeaways and restaurants and other shops and outlets). The legal contract for the purchase of the goods and food delivery service is between you (the customer) and the food provider that you place your order with; we are acting only as an agent.</p>

                <h4>Termination</h4>

                <p>We may terminate your membership or suspend your access to all or part of the Services, without notice, if you violate these Terms or you engage in any conduct that we, in our sole and absolute discretion, believe is in violation of any applicable law or regulation or is otherwise harmful to the interests of us, any other user of the Services, or any third party. You agree that SBS Ltd shall not be liable to you or any third party for removing your user submissions or suspending or terminating your access to the Services (or any portion thereof). You may discontinue your participation in and access to the Services at any time. We reserve the right to investigate your use of the Services in the event we, in our sole and absolute discretion, believe you have violated these Terms. Upon termination, we have no obligation to retain, store, or provide you with any data, information or other content that you uploaded, stored, or transferred on or through the Services, other than as provided by law and in accordance with our Privacy Policy.</p>
                <p>You can request to disable your account at any time, for any reason, by emailing us with the subject “Close My Account”. Please provide as much information as you can about your account, so we may identify the account and you correctly. If we do not receive sufficient information, we will be unable to disable or delete your account.</p>
                <p>Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us, including without limitation the arbitration agreement.</p>

                <table>
                    <tr>
                        <td colspan="2"><h4>Products</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <b>1.1</b>
                        </td>
                        <td>
                            All products as depicted on this website (as may be varied from time to time) are available to browse. Certain product ranges or Services are available in selected delivery areas only.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>1.2</b>
                        </td>
                        <td>
                            For allergies, please contact the restaurant, takeaway, shop or outlet directly to inform them of any allergy or dietary requirements.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><h4>Prices</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <b>3.1</b>
                        </td>
                        <td>
                            Prices listed on the website are updated as per the instructs of the restaurant, takeaway, shop or outlet.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>3.2</b>
                        </td>
                        <td>
                            Except where stated product prices exclude delivery charges.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><h4>Orders, Payments and Contract</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <b>4.1</b>
                        </td>
                        <td>
                            You will be given clear instructions to navigate the order process and will be required to provide us with your complete personal details, including accurate postcode, together with those of the intended recipient and all necessary payment details.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>4.2</b>
                        </td>
                        <td>
                            We accept payment by most major credit and/or debit cards. We reserve the right to refuse a particular card payment from time to time.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>4.3</b>
                        </td>
                        <td>
                            By clicking on the 'Pay Now' button at the end of the order process, you are consenting to be bound by our Terms and Conditions contained in these terms and conditions and appearing anywhere on the website.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>4.4</b>
                        </td>
                        <td>
                            Submitting your order is subject to our acceptance of our offers. We will send an email confirmation of your order details to advise that we are processing your order via the email address you provide. We will not consider ourselves bound by a contract with you until we have issued this email confirmation.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>4.5</b>
                        </td>
                        <td>
                            We cannot accept orders from customers under the age of eighteen (18) years.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><h4>Cancellation/Amendments</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <b>6.1</b>
                        </td>
                        <td>
                            We regret that you may not cancel an order for: (i) perishable goods once your order has been dispatched; or (ii) customised goods if, at the time you seek to cancel, the preparation process has begun.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>6.2</b>
                        </td>
                        <td>
                            In respect of non-perishable, non-prepared goods you may cancel your order from the point at which you place your order by telephoning the relevant the restaurant, takeaway, shop or outlet.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><h4>Customer Contact Centre</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <b>7.1</b>
                        </td>
                        <td>
                            In the event you are not satisfied with any part of our Service, please contact us and allow us to make the situation right. Or, if you prefer, give you your money back where possible.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>7.2</b>
                        </td>
                        <td>
                            Because of the perishable nature of most products and in order to assist us in resolving any complaints quickly and to our mutual satisfaction, we advise you to make any complaints immediately or as soon as possible.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>7.3</b>
                        </td>
                        <td>
                            Any complaints should be addressed in the first instance to:
                            <ul>
                                <li>
                                    <b>By Email:</b><br/>
                                    support@wotfood.co.uk<br/><br/>
                                </li>
                                <li>
                                    <b>By Post:</b><br/>
                                    wotFood Customer Care<br/>
                                    Systems Based Solutions Ltd<br/>
                                    Suite 197, 4 Blenheim Court, Peppercorn Close,<br/>
                                    Peterborough, PE1 2DU
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><h4>Disclaimer</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <b>9.1</b>
                        </td>
                        <td>
                            Whilst we agree to use all reasonable endeavours to ensure that the Website and/or the Service is fully operational and error-free, we cannot guarantee this and therefore accept no responsibility for any defects and/or interruption of the Website and/or the Service and shall be released from our obligations under these Terms and Conditions in the event of any cause beyond our reasonable control which renders the provision of the Website and/or the Service impossible or impractical.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>9.2</b>
                        </td>
                        <td>
                            Abuse of Service: we reserve the right to refuse an order if we believe your use of the Service, either directly or indirectly, is for any unlawful purpose, or, may cause distress or offence to any person.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><h4>Coupons, Vouchers and Promotional Codes</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <b>10.1</b>
                        </td>
                        <td>
                            Any valid promotional codes (also referred to as 'voucher codes' or 'coupons') can be used against purchases made on wotfood.co.uk (or a subdomain of).
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>10.2</b>
                        </td>
                        <td>
                            Promotional codes must be entered in the relevant section of the order process on wotfood.co.uk (or a subdomain of). We are unable to apply a promotional code to orders already placed.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>10.3</b>
                        </td>
                        <td>
                            Promotional codes are usually restricted to one use per customer and should not be passed on to additional recipients.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>10.4</b>
                        </td>
                        <td>
                            Promotional codes may also be set to a maximum number of redemptions - if we are not able to accept the promotional code you have entered it will be because the code has expired or the maximum redemption level for promotion has been met. We will advise you accordingly in the order process and the discount will not be applied.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>10.5</b>
                        </td>
                        <td>
                            Selected promotional codes may be restricted for use against specified products or services - details of any restrictions will be communicated at the point where we promote the promotional code to you.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>10.6</b>
                        </td>
                        <td>
                            Promotional codes cannot be exchanged for a cash alternative or used in conjunction with any other promotions.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>10.7</b>
                        </td>
                        <td>
                            Where the promotional code entitles you to a percentage discount against the product price, this excludes any and all delivery charges.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>10.8</b>
                        </td>
                        <td>
                            Promotional codes may be withdrawn, or suspended without notice for any reason. We will advise you within the order process if we cannot accept the promotional code you have entered.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>10.9</b>
                        </td>
                        <td>
                            We cannot be held responsible for non-availability of wotfood.co.uk (or any subdomain of) which may prevent use of promotional codes.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>10.10</b>
                        </td>
                        <td>
                            Changes to these terms of use may be made at any time.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><h4>General</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <b>11.1</b>
                        </td>
                        <td>
                            We reserve the right to supplement and amend the Terms and Conditions on which you are permitted access to the Website and/or the Service from time to time. We will post any changes on the Website and it is your responsibility as a customer to review the Terms and Conditions on each occasion you access the Service or Website.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>11.2</b>
                        </td>
                        <td>
                            Additionally, we reserve the right to suspend, restrict or terminate access to the Website and/or the Services for any reason at any time.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>11.3</b>
                        </td>
                        <td>
                            These Terms and Conditions shall be deemed to include all other notices, policies, disclaimers and other terms contained in the Website, provided that in the event of a conflict between any such other notices, policies, disclaimers and other terms, these Terms and Conditions shall prevail. If any of these Terms and Conditions is held to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>11.4</b>
                        </td>
                        <td>
                            We shall ensure that we comply with the requirement of all current data protection legislation including, without limitation, the Data Protection Act 1998 (as replaced, modified or re-enacted from time to time). We shall only use personal data received from you for the purpose of fulfilling our obligations under these Terms and Conditions and as stated in our Privacy Policy.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>11.5</b>
                        </td>
                        <td>
                            A person who is not a party to the agreement between you and us has no rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any of the Terms and Conditions but this does not affect any right or remedy which exists apart from that act.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>11.6</b>
                        </td>
                        <td>
                            In respect of fraudulent misrepresentation, this agreement (including any documents and instruments referred to herein) supersedes all prior representations, arrangements, understandings, and agreements between you and us (whether written or oral) and sets forth the entire agreement and understanding between you and us relating to the subject matter hereof.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>11.7</b>
                        </td>
                        <td>
                            Your purchase will be deemed to have occurred in the UK. These Terms and Conditions shall be governed by and construed in accordance with English Law and the parties agree to submit to the exclusive jurisdiction of the English courts.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><h4>Intellectual Property</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <b>13.1</b>
                        </td>
                        <td>
                            All other rights, including copyright, in this website are owned by SBS Ltd. Any use of this website or its contents, including copying or storing it or them in whole or part, other than for your own personal, non-commercial use is prohibited without the permission of SBS Ltd. You may not modify, distribute or repost something on this website for any purpose.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><h4>Our Trade Marks</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <b>14.1</b>
                        </td>
                        <td>
                            The word mark 'wotFood' is owned by SBS Ltd.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><h4>My Account Service</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <b>15.1</b>
                        </td>
                        <td>
                            The 'My Account' service (also referred to as the 'Service' hereafter) is provided as a complimentary feature by wotFood for your personal use subject to these Terms and Conditions. In order to provide the Service, we will hold certain personal information. Except to the extent that we are required or permitted by law, any personal information which we collect will be used solely for the purposes of providing the Service.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>15.2</b>
                        </td>
                        <td>
                            To register with the Service you must be eighteen (18) years of age or over.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>15.3</b>
                        </td>
                        <td>
                            When you register with the Service you will be required to create a password. You must keep this password confidential and must not disclose it or share it with anyone. You will be responsible for all activities and orders that occur or are submitted under your password. If you know or suspect that someone else knows your password you should notify us immediately. Should you forget your password, please use the password reminder service (this option can be found adjacent to the My Account login box).
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>15.4</b>
                        </td>
                        <td>
                            If SBS Ltd has reason to believe that there is likely to be a breach of security or misuse of the Service or Website, we may require you to change your password, or may suspend your use of the Service.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>15.5</b>
                        </td>
                        <td>
                            You must ensure that the details you provide on registration or at any time are correct and complete.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>15.6</b>
                        </td>
                        <td>
                            You must inform us immediately of any changes to the personal information that you provided when registering. Updates can be made at any time by going to the 'About Me' personal profile section of My Account. This will help us to ensure that the Service we provide is accurate and up to date.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>15.7</b>
                        </td>
                        <td>
                            Use of the Service is intended to assist with purchases made via the Website. The Service must not be used for the following purposes:
                            <ul>
                                <li>• Disseminating any unlawful, harassing, libellous, abusive, threatening, harmful, vulgar, obscene, or otherwise objectionable material.</li>
                                <li>• Gaining unauthorised access to other computer systems.</li>
                                <li>• Interfering with any other person's use or enjoyment of the Website.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>15.8</b>
                        </td>
                        <td>
                            SBS Ltd reserves the right to refuse to post material on the Website or to remove material already posted.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>15.9</b>
                        </td>
                        <td>
                            Whilst we take every reasonable precaution and care in relation to the Service, we do not make any representation or warranties of any kind (express or implied) with respect to the contents or operation of the Service, and any such representations and warranties are excluded by this notice. We do not warrant that use of the Service will be uninterrupted or error free. Whilst every effort is made to ensure reminder emails set up on your account are processed and delivered as requested, we cannot be held responsible for non-delivery of these messages. Your use of the Service is done so entirely at your own risk and we do not accept any liability whatsoever for losses or damages that you may suffer as a result of your use of the Service.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>15.10</b>
                        </td>
                        <td>
                            Your access to the Website and Service may occasionally be restricted to allow for routine maintenance.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>15.11</b>
                        </td>
                        <td>
                            These terms and conditions operate in conjunction with general Terms & Conditions of the Website governing overall use and order placement. We are committed to protecting your privacy; further information is contained within our Privacy Policy.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>15.12</b>
                        </td>
                        <td>
                            We have obligations in relation to your personal information under the Data Protection Act 1998. Some of the information you provide may be "sensitive" information under the act (i.e. information on "delicate" matters, including race, political opinions, religion, trade union membership, physical or mental health or condition, sexuality etc.). By registering for the Service and providing your details, you agree to us using information in the way set out above. Where you provide information about other people (for example their date of birth, or their address) you also confirm that you have the consent of these people to provide us with that information.
                        </td>
                    </tr>
                </table>

      </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import ShopHeader from '../components/ShopHeader.vue'
import ShopFooter from '../components/ShopFooter.vue'

export default {
  name: 'Terms',
  components: {
    Header: ShopHeader,
    Footer: ShopFooter
  }
}
</script>
