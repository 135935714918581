import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Shop from '../views/Shop.vue'
import Terms from '../views/Terms.vue'
import Privacy from '../views/Privacy.vue'
import Cookies from '../views/Cookie.vue'
import Contact from '../views/Contact.vue'
import Search from '../views/Search.vue'
import Basket from '../views/Basket.vue'
import Checkout from '../views/Checkout.vue'
import OrderStatus from '../views/OrderStatus.vue'
import Error from '../views/Error.vue'
import NewPassword from '../views/NewPassword.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/shop',
    name: 'shop',
    component: Shop
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: Cookies
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/search',
    name: 'search',
    component: Search
  },
  {
    path: '/:postcode/:name',
    name: 'shop',
    component: Shop
  },
  {
    path: '/basket',
    name: 'Basket',
    component: Basket
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/status',
    name: 'OrderStatus',
    component: OrderStatus
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  },
  {
    path: '/newpassword',
    name: 'newpassword',
    component: NewPassword
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
